import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PiVisionLinkComponent } from './pi-vision-link.component';

@NgModule({
  declarations: [PiVisionLinkComponent],
  imports: [
    CommonModule
  ],
  exports: [PiVisionLinkComponent]
})
export class PiVisionLinkModule { }

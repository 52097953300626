import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AiLoadDistributionComponent } from "./ai-load-distribution.component";
import { LoadingSpinnerModule } from "../loading-spinner/loading-spinner.module";
import { UiGraphModule } from "../ui-graph/ui-graph.module";
import { CardErrorModule } from "../card-error/card-error.module";

@NgModule({
  declarations: [AiLoadDistributionComponent],
  imports: [
    CommonModule,
    LoadingSpinnerModule,
    UiGraphModule,
    CardErrorModule
  ],
  exports: [AiLoadDistributionComponent]
})
export class AiLoadDistributionModule { }
